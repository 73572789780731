import styled from 'styled-components';
import {
  Select,
  TableCell,
  TableHead,
  TableContainer,
  MenuItem,
} from '@material-ui/core';

export const StyledTableContainer = styled(TableContainer)`
  overflow-x: unset !important;
  tbody {
    tr {
      border-style: none;
      &:nth-of-type(odd) {
        background-color: #f5f5f5;
      }
      &:nth-of-type(even) {
        background-color: #ececec;
      }
    }
  }
`;
StyledTableContainer.displayName = 'StyledTableContainer';

export const StyledTableCell = styled(TableCell)`
  font-family: inherit !important;
  font-size: 0.9rem !important;
  color: inherit !important;
  border-bottom: 0 !important;
  word-break: normal;
  white-space: pre-wrap;
`;

StyledTableCell.displayName = 'StyledTableCell';

export const StyledTableHead = styled(TableHead)`
  height: 32px;
  background-color: #868686;
  th {
    line-height: 1em;
    padding: 4px;
    color: white !important;
  }
`;
StyledTableHead.displayName = 'StyledTableHead';

export const StyledSelect = styled(Select)`
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-family: inherit !important;
  font-size: 0.85rem !important;
  color: inherit !important;
`;
