import gql from 'graphql-tag';
import {
  ALITHEON_ATTACHMENTS,
  ALITHEON_EXTENDED_DATA,
  ALL_ITEM_IMAGES,
  BOXES_LIST_ON_STATION,
  FiltersRequirementsQueryFragment,
  HANDBAG_AUTH_DETAILS,
  INPUT_FRAGMENT,
  INPUT_WITH_INPUT_VALUES_FRAGMENT,
  ITEM_LIST_FRAGMENT,
  ITEM_QUANTITY_ON_ORDER,
  ITEM_VALUES_AND_PARAMETERS_ON_ORDER,
  ITEMS_CONNECTION_FRAGMENT,
  OBJECT_EXCEPTION_LIST_FRAGMENT,
  OBJECT_EXCEPTIONS_CONNECTION_FRAGMENT,
  OptimalVaultShipmentBoxFragment,
  ORDER_EXCEPTION_LIST_FRAGMENT,
  ORDER_EXCEPTIONS_CONNECTION_FRAGMENT,
  ORDER_LIST_FRAGMENT,
  ORDER_STATUS_ON_ORDER,
  PSNAD_EXCEPTION,
  SHIPMENT_ON_ORDER,
  STATION_COMPLETED_ITEMS,
  STATION_ITEM_ANSWERS_HISTORY,
  STATION_ITEM_FOR_TIMELINE_ON_ITEM,
  STATION_PENDING_ITEMS,
  STATION_RECEIVED_ITEMS,
} from './fragments';

export const IMS_ENABLED_FLAG = gql`
  query imsEnabledFlag($partnerLocationId: ID!) {
    imsEnabledFlag(partnerLocationId: $partnerLocationId)
  }
`;

export const ALITHEON_REGISTRATION_VALIDATION = gql`
  query alitheonRegistrationValidation($id: ID!) {
    alitheonRegistrationValidation(id: $id)
  }
`;

export const ALITHEON_DATA = gql`
  query alitheonData($id: ID!, $stationId: ID!) {
    alitheonPayload(id: $id, stationId: $stationId)
    alitheonData {
      options {
        value
        text
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      email
      userIdentification
      stations {
        id
        name
      }
      stationUsers {
        id
        station {
          id
          name
          rack
          cart
        }
      }
      roles {
        id
        name
      }
      partnerLocations {
        id
        name
        hubId
        isBrandedPartner
        timeZone
        shippingService {
          id
          name
          hasManifests
        }
        internationalizationCountryCode
        isAllowedToReprintShippingLabel
        manifestableCarriers {
          id
          name
        }
        lpnType
        categoryId
        category {
          id
          name
        }
        userPartnerLocationStations {
          id
          name
          customEndpoint
          isExternalUrl
        }
        hasPsnadStation
        alitheonRegistrationStation
        alitheonAuthenticationStation
        defaultCaseTypeToRaw
        blueBiteAuthenticCardAllowedStatuses
        qcDefaultValues {
          name
          value
        }
        inputMaxLength {
          name
          value
        }
      }
      categories {
        id
        name
      }
    }
  }
`;

export const CURRENT_PARTNER_LOCATION_CONFIGS = gql`
  query currentPartnerLocationConfigs($partnerLocationId: ID!) {
    currentPartnerLocationConfigs(partnerLocationId: $partnerLocationId) {
      id
      fingerprintMethods
      isTitleHeaderConfigOn
      authenticityCardType
      snadCardType
      forceSkuValidationOnSuccess
    }
  }
`;

export const QUERY_STATION = gql`
  ${BOXES_LIST_ON_STATION}
  ${INPUT_FRAGMENT}
  ${INPUT_WITH_INPUT_VALUES_FRAGMENT}
  query queryStation(
    $id: ID!
    $forPackStation: Boolean!
    $forQcAuthStation: Boolean!
    $partnerLocationId: ID
    $categoryId: ID
    $isActive: Boolean
    $withInputValues: Boolean!
    $withoutInputValues: Boolean!
  ) {
    queryStation(id: $id, categoryId: $categoryId) {
      id
      name
      rack
      cart
      ...BoxesList @include(if: $forPackStation)
      inputs(
        categoryId: $categoryId
        isActive: $isActive
        partnerLocationId: $partnerLocationId
      ) @include(if: $forQcAuthStation) {
        ...InputFragment @include(if: $withoutInputValues)
        ...InputFragmentWithInputValues @include(if: $withInputValues)
      }
    }
  }
`;

export const QUERY_INPUTS = gql`
  query queryInputs(
    $categoryId: ID!
    $inputTypes: [String!]!
    $stationIds: [ID!]
  ) {
    queryInputs(
      categoryId: $categoryId
      inputTypes: $inputTypes
      stationIds: $stationIds
    ) {
      id
      key
      inputType
      title
      variant
      parentId
      inputValues {
        id
        displayValue
        value
        parentId
      }
    }
  }
`;

export const AUTHENTICITY_DETAILS = gql`
  query ($itemId: ID!) {
    authenticityDetails(itemId: $itemId) {
      key
      value
    }
  }
`;

export const AUTHENTICITY_SPECIFICS = gql`
  query ($itemId: ID!) {
    authenticitySpecifics(itemId: $itemId) {
      key
      value
    }
  }
`;

export const VAULT_BOXES_FOR_ITEM = gql`
  query vaultBoxesForItem($itemLpn: String!, $partnerLocationId: ID!) {
    vaultBoxesForItem(
      itemLpn: $itemLpn
      partnerLocationId: $partnerLocationId
    ) {
      id
      length
      width
      height
      weight
      boxSize
      boxType
      displayOrder
      isVaultOverpricedType
    }
  }
`;

export const RETRIEVE_OPTIMAL_OPEN_BOX = gql`
  ${OptimalVaultShipmentBoxFragment}
  query retrieveOptimalOpenBox($itemLpn: String, $partnerLocationId: ID!) {
    retrieveOptimalOpenBox(
      itemLpn: $itemLpn
      partnerLocationId: $partnerLocationId
    ) {
      optimalBoxes {
        ...optimalVaultShipmentBoxFragment
      }
      matchingBoxes {
        ...optimalVaultShipmentBoxFragment
      }
    }
  }
`;

export const QUERY_STATIONS = gql`
  query queryStations {
    queryStations {
      id
      name
      rack
      cart
      displayOrder
    }
  }
`;

export const AUTHENTICITY_LEVEL_MATRIX = gql`
  query authenticityLevelMatrix($input: AuthenticityLevelMatrixInput!) {
    authenticityLevelMatrix(input: $input)
  }
`;

export const QUERY_USERS_ACCESS_EXCEPTION = gql`
  query queryUsersAccessException($partnerLocationId: ID!) {
    queryUsersAccessException(partnerLocationId: $partnerLocationId) {
      id
      userIdentification
    }
  }
`;
export const QUERY_EXCEPTION_FLAG_VALUES = gql`
  query queryExceptionFlagValues($stationId: ID, $categoryId: ID) {
    queryExceptionCodes(stationId: $stationId, categoryId: $categoryId) {
      id
      name
      categoryId
      category {
        id
        name
      }
      parentCodeId
      stationId
      station {
        id
        name
      }
    }
  }
`;

export const QUERY_EXCEPTIONS = gql`
  ${ITEM_VALUES_AND_PARAMETERS_ON_ORDER}
  ${ORDER_STATUS_ON_ORDER}
  ${ITEM_QUANTITY_ON_ORDER}
  ${SHIPMENT_ON_ORDER}
  ${STATION_ITEM_FOR_TIMELINE_ON_ITEM}
  ${HANDBAG_AUTH_DETAILS}
  ${PSNAD_EXCEPTION}
  ${ALL_ITEM_IMAGES}
  query queryExceptions(
    $id: ID
    $relatedObjectType: String
    $partnerLocationIds: [String!]
    $withId: Boolean!
    $forPackStation: Boolean!
    $openExceptionOnly: Boolean
  ) {
    queryExceptions(
      id: $id
      relatedObjectType: $relatedObjectType
      partnerLocationIds: $partnerLocationIds
      openExceptionOnly: $openExceptionOnly
    ) {
      id
      createdAt
      exceptionStatus
      flagType
      resolvedAt
      flagValue
      userIdentification
      firstName
      lastName
      gcxTicketNumber
      gcxTicketUrl
      relatedObjectId
      relatedObjectType
      psnadException {
        ...PsnadException
      }
      actualLocations {
        id
        identifier
      }
      assignedTo {
        id
        userIdentification
      }
      createdBy {
        id
        email
        username
        firstName
        lastName
        userIdentification
      }
      resolvedBy {
        id
        email
        username
        firstName
        lastName
      }
      allNotes {
        id
        description
        userIdentification
        firstName
        lastName
        createdAt
        filesInfo {
          url
          filename
        }
      }
      notes {
        id
        description
      }
      station {
        id
        name
      }
      exceptionCode {
        id
        name
        relatedId
        displayName
      }
      item {
        id
        name
        alitheonStatus
        itemBranded
        itemCertificationNumber
        isExport
        exportCountry
        isInHandSubmission
        isConfirmReceived
        isReadyForPackaging
        isVaultShipping
        ...AllItemImages
        authenticationDetail {
          orderId
        }
        category {
          id
          name
        }
        ebayItem {
          id
          evtn
          unitId
          imageUrls
          itemUrl
          authenticationLevel
          psaMode
          signatureRequired
        }
        lpn {
          id
          lpn
          createdAt
        }
        order {
          id
          evtn
          imageUrls
          isVaultShipping
          isFulfillment
          shipped
          isVaultBound
          isInHandSubmission
          ebayOrderState
          trackings {
            id
            trackingNumber
          }
          ebayOrder {
            id
            itemUrl
            imageUrls
            whiteGlove
            supervisedAuthentication
          }
          ebayStatus
          ...OrderStatus @include(if: $withId)
          ...ItemQuantity @include(if: $withId)
          ...Shipment @include(if: $withId)
          items {
            category {
              id
              name
            }
            id
            isVaultShipping
            isVaultBound
            itemBranded
            ...StationItemForTimeline @include(if: $withId)
            ebayStatus
            name
            description
            ...AllItemImages
            currentLocation {
              id
              identifier
            }
            lpn {
              id
              lpn
            }
          }
          partnerLocation {
            name
            timeZone
          }
          ...OrderItemValuesAndParameters @include(if: $withId)
        }
      }
      order {
        id
        evtn
        isReturn
        imageUrls
        isVaultShipping
        isFulfillment
        shipped
        isVaultBound
        isExport
        exportCountry
        isInHandSubmission
        authenticationDetailStatus
        nfcTag {
          tagId
          tagUrl
        }
        ebayOrderState
        trackings {
          id
          carrier
          trackingNumber
        }
        ebayOrder {
          id
          itemUrl
          imageUrls
          whiteGlove
          supervisedAuthentication
        }
        ebayStatus
        ...OrderStatus @include(if: $withId)
        ...ItemQuantity @include(if: $withId)
        ...Shipment @include(if: $withId)
        items {
          category {
            id
            name
          }
          id
          itemBranded
          isNoShippingRequired
          ...StationItemForTimeline @include(if: $withId)
          ebayStatus
          isNoShippingRequired
          orderId
          ebayItem {
            id
            evtn
            unitId
            imageUrls
            authenticationLevel
            psaMode
            itemPrice
            signatureRequired
            title
            itemUrl
            itemCondition
            itemAttributes {
              name
              value
            }
          }
          name
          description
          ...HandbagAuthDetails
          ...AllItemImages
          currentLocation {
            id
            identifier
          }
          lpn {
            id
            lpn
          }
          qcStationItem {
            id
            userIdentification
            stationItemAnswers {
              id
              grade
              text
              input {
                id
                snadCode
                title
              }
            }
          }
          psnadStationItem {
            id
            stationItemAnswers {
              id
              grade
              input {
                snadCode
                id
              }
            }
          }
        }
        partnerLocation {
          hubId
          name
          timeZone
        }
        ...OrderItemValuesAndParameters @include(if: $withId)
      }
    }
  }
`;

export const QUERY_NO_SHIPPING_REQUIRED_STATUSES = gql`
  query queryNoShippingRequiredStatuses {
    data: queryNoShippingRequiredStatuses {
      id
      value
    }
  }
`;

export const QUERY_EXCEPTION_RESOLUTION_ACTIONS = gql`
  query queryExceptionResolutionActions {
    data: queryExceptionResolutionActions {
      id
      value
    }
  }
`;

export const EBAY_ORDER_EXCEPTION = gql`
  query ebayOrderException(
    $tracking: String
    $partnerLocationId: String
    $toFirst: String
    $toLast: String
    $fromFirst: String
    $fromLast: String
  ) {
    ebayOrderException(
      tracking: $tracking
      partnerLocationId: $partnerLocationId
      toFirst: $toFirst
      toLast: $toLast
      fromFirst: $fromFirst
      fromLast: $fromLast
    ) {
      id
      evtn
      itemQuantity
      itemCondition
      itemUrl
      trackingNumbers {
        carrier
        trackingNumber
      }
      isReturn
      authenticationLevel
      signatureRequired
      relatedEvtns {
        evtn
      }
      title
      description
      imageUrls
      authenticationNotes {
        timestamp
        noteType
        description
      }
    }
  }
`;

export const PRINTERS_LIST = gql`
  query printersList($stationId: ID!, $partnerLocationId: ID!) {
    printersList(stationId: $stationId, partnerLocationId: $partnerLocationId) {
      id
      name
      printers {
        id
        printerName
        ipAddress
        canSelectLabel
      }
    }
  }
`;

export const QUERY_GUIDE = gql`
  query queryGuide($id: ID!, $brandInputValueId: ID, $stationId: ID) {
    queryGuide(
      id: $id
      brandInputValueId: $brandInputValueId
      stationId: $stationId
    )
  }
`;

export const QUERY_PDF_GUIDES = gql`
  query queryPdfGuides(
    $inputTypeAnswer: String!
    $guidableType: String!
    $isActive: Boolean
    $stationId: ID
  ) {
    queryPdfGuides(
      inputTypeAnswer: $inputTypeAnswer
      guidableType: $guidableType
      isActive: $isActive
      stationId: $stationId
    ) {
      id
      isActive
      pdf {
        filename
        url
      }
    }
  }
`;

export const QUERY_ITEM_COLLATERALS = gql`
  query queryItemCollaterals($itemId: ID!) {
    queryItemCollaterals(itemId: $itemId) {
      id
      itemId
      name
      lpn {
        id
        lpn
      }
    }
  }
`;

export const QUERY_CATEGORY_DUST_BAGS = gql`
  query queryCategoryDustBags($categoryId: ID!) {
    queryCategoryDustBags(categoryId: $categoryId) {
      id
      dustBagSize
      length
      width
      height
      displayOrder
      containerType
    }
  }
`;

export const QUERY_PARTNER_LOCATION_BOXES = gql`
  query queryPartnerLocationBoxes($partnerLocationId: ID!, $boxType: String!) {
    queryPartnerLocationBoxes(
      partnerLocationId: $partnerLocationId
      boxType: $boxType
    ) {
      id
      length
      width
      height
      weight
      boxSize
      boxType
      displayOrder
    }
  }
`;

export const QUERY_CATEGORY_DUST_BAGS_AND_PARTNER_LOCATION_BOXES = gql`
  query queryCategoryDustBagsAndPartnerLocationBoxes(
    $categoryId: ID!
    $partnerLocationId: ID!
    $boxType: String!
  ) {
    queryCategoryDustBags(categoryId: $categoryId) {
      id
      dustBagSize
      length
      width
      height
      displayOrder
      containerType
    }
    queryPartnerLocationBoxes(
      partnerLocationId: $partnerLocationId
      boxType: $boxType
    ) {
      id
      length
      width
      height
      weight
      boxSize
      boxType
      displayOrder
    }
  }
`;

export const QUERY_NOTABLE_NOTES = gql`
  query queryNotableNotes(
    $notableId: ID!
    $notableType: String!
    $visibilities: [String!]!
    $level: NotableLevelEnum
  ) {
    queryNotableNotes(
      notableId: $notableId
      notableType: $notableType
      visibilities: $visibilities
      level: $level
    ) {
      id
      description
      userIdentification
      updatedAt
      createdAt
      firstName
      lastName
      filesInfo {
        filename
        url
      }
    }
  }
`;

export const QUERY_PREDEFINED_NOTES = gql`
  query predefinedNotes($filters: PredefinedNotesFilters!, $stationId: ID) {
    predefinedNotes(filters: $filters, stationId: $stationId) {
      edges {
        cursor
        node {
          id
          gid
          createdAt
          description
          categoryId
          category {
            id
            name
          }
          partnerLocationId
          partnerLocation {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const QUERY_GRADERS_AND_GRADES_INPUTS = gql`
  query queryGradersAndGradesInputs(
    $orderId: ID!
    $itemId: ID!
    $cardCategory: CardCategoryEnum!
  ) {
    queryGradersAndGradesInputs(
      orderId: $orderId
      itemId: $itemId
      cardCategory: $cardCategory
    ) {
      inputId
      name
      inputValues {
        id
        value
        valueId
      }
    }
  }
`;

export const QUERY_CONDITION_VALUE = gql`
  query queryConditionValues {
    queryConditionValues {
      id
      value
      valueId
    }
  }
`;

export const QUERY_INPUT_VALUES_FOR_GRADER = gql`
  query queryInputValuesForGrader(
    $graderValueId: String!
    $cardCategory: CardCategoryEnum!
  ) {
    queryInputValuesForGrader(
      graderValueId: $graderValueId
      cardCategory: $cardCategory
    ) {
      inputId
      name
      inputValues {
        id
        value
        valueId
      }
    }
  }
`;

export const QUERY_DASHBOARD_REPORTS = gql`
  ${FiltersRequirementsQueryFragment}
  ${ITEMS_CONNECTION_FRAGMENT}
  ${STATION_PENDING_ITEMS}
  ${STATION_COMPLETED_ITEMS}
  ${STATION_RECEIVED_ITEMS}
  ${ITEM_LIST_FRAGMENT}
  query adminReports(
    $partnerLocationFilter: PartnerLocationFilter
    $input: ReportsFiltersInput!
    $last: Int
    $before: String
    $pendingItems: Boolean!
    $receivedItems: Boolean!
    $completedItems: Boolean!
  ) {
    tickerReports(input: $input) {
      partnerLocation {
        id
        name
      }
      stationReports {
        receivedCount
        completedCount
        pendingCount
        statusName
        ...StationPendingItems @include(if: $pendingItems)
        ...StationReceivedItems @include(if: $receivedItems)
        ...StationCompletedItems @include(if: $completedItems)
      }
    }
    ...ItemsHistoryQueryFragment
  }
`;

export const QUERY_ORDER_EXCEPTIONS_CONNECTION = gql`
  ${ORDER_EXCEPTIONS_CONNECTION_FRAGMENT}
  ${ORDER_EXCEPTION_LIST_FRAGMENT}
  query orderExceptions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filters: OrderExceptionFilters
  ) {
    orderExceptions(
      first: $first
      last: $last
      after: $after
      before: $before
      filters: $filters
    ) {
      ...OrderExceptionsConnectionFragment
    }
  }
`;

export const QUERY_OBJECT_EXCEPTIONS_CONNECTION = gql`
  ${OBJECT_EXCEPTIONS_CONNECTION_FRAGMENT}
  ${OBJECT_EXCEPTION_LIST_FRAGMENT}
  query objectExceptions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filters: ObjectExceptionFilters
  ) {
    objectExceptions(
      first: $first
      last: $last
      after: $after
      before: $before
      filters: $filters
    ) {
      ...ObjectExceptionsConnectionFragment
    }
  }
`;

export const QUERY_PSNAD_EXCEPTION_CONNECTION = gql`
  ${PSNAD_EXCEPTION}
  ${ALL_ITEM_IMAGES}
  query psnadExceptions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filters: PsnadExceptionFilters
  ) {
    psnadExceptions(
      first: $first
      last: $last
      after: $after
      before: $before
      filters: $filters
    ) {
      edges {
        node {
          id
          flagType
          flagValue
          actualLocations {
            id
            identifier
          }
          gcxTicketUrl
          order {
            id
            evtn
            items {
              id
              currentLocation {
                id
                identifier
              }
              lpn {
                id
                lpn
              }
              ...AllItemImages
            }
          }
          psnadException {
            ...PsnadException
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const QUERY_PSNAD_STATUSES = gql`
  query psnadStatuses {
    psnadStatuses {
      id
      value
    }
  }
`;

export const QUERY_ACTIONABLE_PSNAD_COUNT = gql`
  query actionablePsnadCount($partnerLocationId: ID!) {
    actionablePsnadCount(partnerLocationId: $partnerLocationId)
  }
`;

export const QUERY_ITEM_IMAGES = gql`
  query itemImages($itemId: ID, $objectExceptionId: ID) {
    itemImages(itemId: $itemId, objectExceptionId: $objectExceptionId) {
      itemImageId
      imageUrl
      isPsnad
      isAlitheon
      title
      objectExceptionId
    }
  }
`;

export const QUERY_ITEMS_CONNECTION = gql`
  ${ORDER_LIST_FRAGMENT}
  ${ITEM_LIST_FRAGMENT}
  ${FiltersRequirementsQueryFragment}
  query itemsConnection(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filters: ItemFilters
    $partnerLocationFilter: PartnerLocationFilter
    $input: ReportsFiltersInput
    $withInput: Boolean
  ) {
    items(
      first: $first
      last: $last
      after: $after
      before: $before
      filters: $filters
    ) {
      edges {
        node {
          ... on Item {
            ...ItemListFragment
          }
          ... on Order {
            ...OrderListFragment
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const QUERY_ALITHEON_LPN = gql`
  ${ALITHEON_EXTENDED_DATA}
  ${ALITHEON_ATTACHMENTS}
  query alitheonFeeds(
    $lpn: String!
    $isRma: Boolean!
    $extendedData: Boolean!
    $attachments: Boolean!
  ) {
    alitheonFeeds(lpn: $lpn, isRma: $isRma) {
      lpn
      resultCode
      recordId
      status
      ...AlitheonExtendedData @include(if: $extendedData)
      ...AlitheonAttachments @include(if: $attachments)
    }
  }
`;

export const QUERY_TAXONOMY = gql`
  query queryTaxonomy($orderId: ID!) {
    queryTaxonomy(orderId: $orderId)
  }
`;

export const AVAILABLE_STATIONS = gql`
  query availableStations($partnerLocationList: [ID!]!) {
    availableStations(partnerLocationList: $partnerLocationList)
  }
`;

export const CARRIER_MANIFESTS = gql`
  query carrierManifests(
    $partnerLocationId: ID
    $startDate: String
    $endDate: String
  ) {
    carrierManifests(
      partnerLocationId: $partnerLocationId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      manifestAt
      shipmentsCount
      refNumber
      carrier {
        id
        name
      }
      partnerLocation {
        id
        name
        hubId
        shippingService {
          id
          name
          hasManifests
        }
      }
      url
    }
  }
`;

export const AVAILABLE_ORDERS_FOR_TESTING_DO_NOT_USE_IN_PRODUCTION = gql`
  query availableOrdersForTesting($partnerLocationId: ID!) {
    availableOrdersForTesting(partnerLocationId: $partnerLocationId) {
      singleItemOrder {
        id
        evtn
        itemQuantity
      }
      multiItemOrder {
        id
        evtn
        itemQuantity
      }
      returnItemOrder {
        id
        evtn
        itemQuantity
      }
      singleItemOrderVault {
        id
        evtn
        itemQuantity
      }
      multiItemOrderVault {
        id
        evtn
        itemQuantity
      }
      ihsOrder {
        id
        evtn
        itemQuantity
      }
    }
  }
`;

export const FETCH_ITEM_TIMELINE_STATION_STATUSES = gql`
  ${STATION_ITEM_ANSWERS_HISTORY}
  query fetchItemTimelineStationStatuses(
    $itemId: ID!
    $stationId: ID
    $isHistory: Boolean!
  ) {
    fetchItemTimelineStationStatuses(itemId: $itemId, stationId: $stationId) {
      id
      createdAt
      updatedAt
      stationId
      station {
        id
        name
      }
      nextStationId
      nextStation {
        id
        name
      }
      stationItems {
        timelineStationStatus
        station {
          id
          name
        }
        status
        grade
        id
        createdAt
        updatedAt
        completedAt
        firstName
        lastName
        userIdentification
        ...StationItemAnswersHistory @include(if: $isHistory)
      }
      objectExceptions {
        id
        createdAt
        updatedAt
        resolvedAt
        gcxTicketNumber
        exceptionStatus
        stationId
        resolvedBy {
          username
          firstName
          lastName
        }
        notes {
          id
          description
        }
        createdBy {
          id
          userIdentification
        }
        exceptionCode {
          id
          name
        }
        assignedTo {
          id
          userIdentification
        }
      }
      order {
        id
        status
        objectExceptions {
          id
          createdAt
          updatedAt
          resolvedAt
          gcxTicketNumber
          exceptionStatus
          stationId
          resolvedBy {
            username
            firstName
            lastName
          }
          notes {
            id
            description
          }
          createdBy {
            id
            userIdentification
          }
          exceptionCode {
            id
            name
          }
          assignedTo {
            id
            userIdentification
          }
        }
        partnerLocation {
          id
          name
          timeZone
          hubId
        }
      }
      category {
        id
        name
      }
    }
  }
`;

export const HOURS_IN_SLA_TICKER_REPORTS = gql`
  query hoursInSlaTickerReports(
    $input: ReportsFiltersInput!
    $slaType: SlaTypeEnum!
    $isIhsOnly: Boolean!
  ) {
    hoursInSlaTickerReports(
      input: $input
      slaType: $slaType
      isIhsOnly: $isIhsOnly
    ) {
      lessThanADay
      betweenOneTwoDays
      betweenTwoThreeDays @include(if: $isIhsOnly)
      betweenThreeFourDays @include(if: $isIhsOnly)
      greaterThanFourDays @include(if: $isIhsOnly)
      greaterThanTwoDays @skip(if: $isIhsOnly)
    }
  }
`;

export const AUTHENTICITY_STATUS_TICKER_REPORTS = gql`
  query authenticityStatusTickerReports(
    $input: ReportsFiltersInput!
    $cardCategory: CardCategoryEnum
    $psaMode: PsaModeEnum
  ) {
    authenticityStatusTickerReports(
      input: $input
      cardCategory: $cardCategory
      psaMode: $psaMode
    ) {
      authenticDeep
      authenticShallow
      authenticRaw
      authenticGraded
      notAsDescribed
      notAuthentic
      notAuthenticException
      customized
      miscategorized
      customizedReturns
      miscategorizedReturns
      cancelled
      nonVerified
      total
    }
  }
`;

export const HOURLY_REPORTS = gql`
  query hourlyReport($partnerLocationId: String!) {
    hourlyReport(partnerLocationId: $partnerLocationId) {
      generatedAtTime
      generatedAtDay
      hourlyData {
        hour
        isCurrentHour
        hourlyColumnCount {
          count
          column
        }
      }
    }
  }
`;

export const ITEMS_AUTHENTICITY = gql`
  ${ITEM_LIST_FRAGMENT}
  query itemsAuthenticity(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $input: ReportsFiltersInput!
    $authenticity: AuthenticityEnum!
    $cardCategory: CardCategoryEnum
    $psaMode: PsaModeEnum
  ) {
    itemsAuthenticity(
      first: $first
      last: $last
      after: $after
      before: $before
      input: $input
      authenticity: $authenticity
      cardCategory: $cardCategory
      psaMode: $psaMode
    ) {
      edges {
        node {
          ... on Item {
            ...ItemListFragment
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const AD_HOC_REPORTS_FIELDS = gql`
  query adHocReportsFields {
    adHocReportsFields
  }
`;

export const ITEMS_IN_PROCESSING_QUEUE = gql`
  ${ITEM_LIST_FRAGMENT}
  query itemsInProcessingQueue(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $input: ReportsFiltersInput!
    $station: String!
    $queueStatus: ProcessingQueueStatusEnum!
  ) {
    itemsInProcessingQueue(
      first: $first
      last: $last
      after: $after
      before: $before
      input: $input
      station: $station
      queueStatus: $queueStatus
    ) {
      edges {
        node {
          ... on Item {
            ...ItemListFragment
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const ITEMS_IN_EXCEPTION = gql`
  ${ITEM_LIST_FRAGMENT}
  query itemsInException(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $input: ReportsFiltersInput!
    $codeId: Int!
  ) {
    itemsInException(
      first: $first
      last: $last
      after: $after
      before: $before
      input: $input
      codeId: $codeId
    ) {
      edges {
        node {
          ... on Item {
            ...ItemListFragment
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const ITEMS_IN_NO_SHIPPING_EXCEPTION = gql`
  ${ITEM_LIST_FRAGMENT}
  query itemsInNoShippingException(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $input: ReportsFiltersInput!
    $codeId: Int!
  ) {
    itemsInNoShippingException(
      first: $first
      last: $last
      after: $after
      before: $before
      input: $input
      codeId: $codeId
    ) {
      edges {
        node {
          ... on Item {
            ...ItemListFragment
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const ITEMS_IN_CURRENT_HOUR_QUEUE = gql`
  ${ITEM_LIST_FRAGMENT}
  query itemsInCurrentHourQueue(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $partnerLocationId: String!
    $columnName: String!
  ) {
    itemsInCurrentHourQueue(
      first: $first
      last: $last
      after: $after
      before: $before
      partnerLocationId: $partnerLocationId
      columnName: $columnName
    ) {
      edges {
        node {
          ... on Item {
            ...ItemListFragment
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const QUERY_ALL_CARRIERS = gql`
  query queryCarriers {
    queryCarriers {
      id
      name
    }
  }
`;

export const QUERY_MANUAL_CARRIERS = gql`
  query queryManualCarriers($partnerLocationId: ID!) {
    queryManualCarriers(partnerLocationId: $partnerLocationId) {
      id
      name
    }
  }
`;

export const SERVICE_LEVELS = gql`
  query serviceLevels($input: ReportsFiltersInput!) {
    serviceLevels(input: $input) {
      id
      carrierId
      name
    }
  }
`;

export const QUERY_ITEM = gql`
  query item($id: ID!) {
    item(id: $id) {
      id
      order {
        id
        evtn
      }
      lpn {
        id
        lpn
      }
      itemImages {
        id
        zoomUrl
        caption
        itemImageType
        imageUrl
      }
    }
  }
`;

export const INPUT_VALUE_NEAR_MATCH = gql`
  query inputValueNearMatch(
    $inputId: ID!
    $value: String!
    $inputParentValueId: ID
  ) {
    inputValueNearMatch(
      inputId: $inputId
      value: $value
      inputParentValueId: $inputParentValueId
    ) {
      value
    }
  }
`;

export const QUERY_ORDERS_BY_SERIAL_NUMBER = gql`
  query queryOrdersBySerialNumber($orderId: ID!, $serialNumber: String!) {
    QueryOrdersBySerialNumber(orderId: $orderId, serialNumber: $serialNumber) {
      lpn
      evtn
    }
  }
`;

export const QUERY_ROLES = gql`
  query queryRoles($accessibleRolesOnly: Boolean) {
    queryRoles(accessibleRolesOnly: $accessibleRolesOnly) {
      id
      name
      displayName
    }
  }
`;

export const QUERY_CATEGORIES = gql`
  query queryCategories {
    queryCategories {
      id
      name
    }
  }
`;

export const QUERY_BULK_UPDATE_ADMIN_PANEL_USER_PERMISSIONS = gql`
  query queryBulkUpdateAdminPanelUserPermissions($ids: [ID!]!) {
    queryBulkUpdateAdminPanelUserPermissions(ids: $ids) {
      isUpdatable
    }
  }
`;

export const QUERY_USER_GROUP_RULE_AUDITS = gql`
  query queryUserGroupRuleAudits($startDate: String, $endDate: String) {
    queryUserGroupRuleAudits(startDate: $startDate, endDate: $endDate) {
      userEmail
      username
      userId
      createdAt
      addedLocationList {
        groupName
        values
      }
      addedCategoryList {
        groupName
        values
      }
      addedStationList {
        groupName
        values
      }
      removedLocationList {
        groupName
        values
      }
      removedCategoryList {
        groupName
        values
      }
      removedStationList {
        groupName
        values
      }
      edits {
        id
        action
        userGroupRuleName
        userGroupRuleNameUpdated
        partnerLocationNameUserGroupRule
        categoryNameUserGroupRule
        stationNameUserGroupRule
      }
    }
  }
`;

export const QUERY_FILTERED_PARTNER_LOCATIONS = gql`
  query queryFilteredPartnerLocations(
    $roleId: ID
    $filter: PartnerLocationCategoriesFilter!
  ) {
    queryFilteredPartnerLocations(roleId: $roleId, filter: $filter) {
      partnerLocationsIds
      name
      defaultSelected
    }
  }
`;

export const QUERY_STATIONS_ADMIN_PANEL = gql`
  query queryStationsAdminPanel {
    queryStationsAdminPanel {
      id
      name
      rack
      cart
      displayOrder
    }
  }
`;

export const QUERY_PARTNER_LOCATIONS = gql`
  query queryPartnerLocations {
    queryPartnerLocations {
      id
      name
    }
  }
`;

export const QUERY_ALL_LIVE_EVENTS = gql`
  query queryAllLiveEvents {
    allLiveEvents {
      id
      name
      isActive
    }
  }
`;

export const LIVE_EVENT_PACKING_SLIP = gql`
  query liveEventPackingSlip($orderId: ID!) {
    liveEventPackingSlip(orderId: $orderId) {
      zpl
    }
  }
`;

export const QUERY_HUB_SERVICE_GROUP = gql`
  query queryHubServiceGroup($partnerLocationId: ID!) {
    queryHubServiceGroup(partnerLocationId: $partnerLocationId) {
      id
      isActive
      mondayPickupTime
      tuesdayPickupTime
      wednesdayPickupTime
      thursdayPickupTime
      fridayPickupTime
      saturdayPickupTime
      sundayPickupTime
      isMondayActive
      isTuesdayActive
      isWednesdayActive
      isThursdayActive
      isFridayActive
      isSaturdayActive
      isSundayActive
      serviceGroup {
        id
        name
      }
      createdAt
      updatedAt
      overridePickupTs
      nextDispatchTime
    }

    PartnerLocation(id: $partnerLocationId) {
      dailyPhotoRobotImagingCapacity
      hasImagingStation
    }
  }
`;

export const QUERY_PHOTO_CATALOG = gql`
  query queryPhotoCatalog($value: String!, $inputId: String) {
    queryPhotoCatalog(value: $value, inputId: $inputId) {
      inputId
      filter
      options {
        label
        value
      }
    }
  }
`;

export const GetOutboundShippingDetails = gql`
  query GetOutboundShippingDetails($item_id: ID!) {
    outboundShippingDetails(itemId: $item_id) {
      id
      isNoShippingRequired
      isInHandSubmission
      isVaultShipping
      shipmentBox {
        trackingNumber
        shipment {
          carrier {
            name
            image
          }
          deliveryDate
          createdAt
          signatureRequired
          createdByException
        }
        shippingLabels {
          id
          createdAt
          fileUrl
          tracking
        }
        lpn {
          lpn
        }
      }
      order {
        ebayOrder {
          signatureRequired
        }
        orderExceptions {
          notes {
            description
          }
        }
        shipment {
          createdByException
          address {
            streetLine1
            streetLine2
            city
            stateProvinceCode
            countryCode
          }
        }
      }
    }
  }
`;
export const QUERY_AUTHENTICY_MATRIX = gql`
  query GetAuthenticityMatrix($itemId: ID!) {
    authenticityMatrix(itemId: $itemId) {
      authentication
      condition
      customized
      miscategorized
      description
      psnad
    }
  }
`;
export const GetIsLiveEvent = gql`
  query IsLiveEvent($item_id: ID!) {
    isLiveEvent(itemId: $item_id) {
      key
      value
    }
  }
`;

export const QUERY_AUTAGGING_BY_EVTN = gql`
  query queryAutotaggingByEvtn($evtn: ID!) {
    autotaggingByEvtn(evtn: $evtn) {
      evtn
      results {
        name
        tags
        sentences
      }
    }
  }
`;
