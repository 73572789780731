import React, { useCallback, useEffect, useState } from 'react';
import { Grid, ListItem, Typography } from '@material-ui/core';
import {
  COLORS,
  Dropdown,
  List,
  Toggle,
} from '@codeboxxtechschool/ginza_shared_components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../customAccordion';
import useStyles from './useStyles';
import { CATEGORY } from '../../../../constants';
import { StyledMenuItem } from '../../../reusables/table/v2/index.styles';

const AccordionToggleList = ({
  title,
  listItems,
  onUpdateListItems,
  checkType,
  setShouldBeDisabled,
  category,
}) => {
  const { t } = useTranslation();
  const isSneakerCategory = category === CATEGORY.sneaker;

  const classes = useStyles();
  const checked = checkType === 'fail' ? 'unchecked' : 'checked';
  const checkedItemCount =
    listItems?.length > 0 ? listItems.filter((li) => li.isChecked).length : 0;

  const disableButtons = useCallback(() => {
    setShouldBeDisabled(true);
  }, [setShouldBeDisabled]);

  const checkShouldBeDisabled = (list) => {
    const hasL1withChildSelected = list.some(
      (li) =>
        li.isChecked === true &&
        Array.isArray(li.childInputs) &&
        li.childInputs.length > 0
    );

    const hasAtLeastOneL2Selected = list.every(
      (li) =>
        Array.isArray(li.childInputs) &&
        li.childInputs.every((li2) => !li2.isChecked)
    );

    setShouldBeDisabled(hasL1withChildSelected && hasAtLeastOneL2Selected);
    if (hasL1withChildSelected && hasAtLeastOneL2Selected) {
      disableButtons();
    }
  };

  const handleToggleClick = (label) => {
    const updateIndex = listItems.findIndex((obj) => obj.label === label);

    if (updateIndex === -1) return;

    const updatedListItems = [...listItems];
    const updatedIsChecked = !updatedListItems[updateIndex].isChecked;

    updatedListItems[updateIndex].isChecked = updatedIsChecked;

    if (!updatedIsChecked) {
      updatedListItems[updateIndex].childInputs = updatedListItems[
        updateIndex
      ].childInputs.map((childInput) => ({
        ...childInput,
        isChecked: false,
      }));
    }

    onUpdateListItems(updatedListItems);
    checkShouldBeDisabled(updatedListItems);
  };

  const handleSecondLevelSelect = (childLabel, selectedLabel) => {
    // Find the parent input index in the list
    const updateIndex = listItems.findIndex((obj) => obj.label === childLabel);
    if (updateIndex === -1) return;

    // Clone the target parent input for updates
    const updatedParentInput = { ...listItems[updateIndex] };
    updatedParentInput.isChecked = true;

    // Update the childInputs, toggling isChecked for the selectedLabel
    updatedParentInput.childInputs = updatedParentInput.childInputs.map(
      (childInput) => {
        if (childInput.label === selectedLabel) {
          return {
            ...childInput,
            isChecked: true, // Set to true for the selected child
          };
        }
        return {
          ...childInput,
          isChecked: false, // Uncheck others
        };
      }
    );

    // Update the grandparent input with the modified parent input
    const updatedGrandParentInput = [...listItems];
    updatedGrandParentInput[updateIndex] = updatedParentInput;

    // Call the external update handlers
    onUpdateListItems(updatedGrandParentInput);
    checkShouldBeDisabled(updatedGrandParentInput);
  };

  const handleAllSelect = () => {
    const allChecked = listItems.every((obj) => obj.isChecked === true);
    const noneChecked = listItems.every((obj) => obj.isChecked === false);
    const someChecked = listItems.some((obj) => obj.isChecked === true);

    listItems.forEach((child) => {
      let updateIndex;

      if (allChecked || noneChecked) {
        updateIndex = listItems.findIndex((obj) => obj.label === child.label);
      } else if (someChecked) {
        updateIndex = listItems.findIndex((obj) => obj.isChecked === true);
      }

      if (updateIndex === -1) return;

      const updatedListItems = [...listItems];
      if (allChecked || noneChecked) {
        updatedListItems[updateIndex].isChecked = !child.isChecked;
        updatedListItems[updateIndex].childInputs = updatedListItems[
          updateIndex
        ].childInputs.map((childInput) => ({
          ...childInput,
          isChecked: false,
        }));
        onUpdateListItems(updatedListItems);
      } else if (someChecked) {
        updatedListItems[updateIndex].isChecked = false;
        updatedListItems[updateIndex].childInputs = updatedListItems[
          updateIndex
        ].childInputs.map((childInput) => ({
          ...childInput,
          isChecked: false,
        }));
        onUpdateListItems(updatedListItems);
      }

      checkShouldBeDisabled(updatedListItems);
    });
  };

  const hasPhotoRequiredSelected = listItems.some((li) => {
    if (isSneakerCategory) {
      return li.childInputs.length === 0
        ? li.isPhotoRequired && li.isChecked
        : li.childInputs?.some((child) => {
            return child.isPhotoRequired && child.isChecked;
          }) ?? false;
    }

    // For other categories
    return li.isPhotoRequired && li.isChecked;
  });

  return (
    <>
      <CustomAccordion
        title={title}
        checkedItemCount={checkedItemCount}
        displayTitleWarning={hasPhotoRequiredSelected}
        handleAllSelect={handleAllSelect}
        checkType={checkType}
      >
        <List
          bottomDivider
          topDivider={false}
          dividerColor={COLORS.gray100}
          width="100%"
        >
          {listItems?.length > 0 &&
            listItems.map((li, index) => {
              const withSecondLevel = li.childInputs?.length > 0;
              const hasSelectedRadio = li.childInputs.some(
                (child) => child?.isChecked
              );

              const displayWarning =
                withSecondLevel && li.isChecked && !hasSelectedRadio;

              return (
                <ListItem key={`list-item-inputId-${li.inputId}`}>
                  <div className={classes.listContainer}>
                    <div
                      className={`${classes.flexRow} ${classes.accordionItem}`}
                    >
                      <Toggle
                        onClick={() => handleToggleClick(li.label)}
                        totalStates={3}
                        value={li.isChecked ? checked : 'neutral'}
                        data-testid={`toggle-switch-${li.label}-${index}`}
                      />
                      <Typography
                        data-testid="accordion-item-label"
                        style={{ marginLeft: '6px' }}
                        variant="body1"
                      >
                        {li.label}
                      </Typography>
                      {withSecondLevel && displayWarning && (
                        <Typography
                          variant="body1"
                          className={classes.warning}
                          style={{ color: '#CC0011' }}
                        >
                          {t('PSNAD.SubmissionForm.SubReasonWarning')}
                          {disableButtons()}
                        </Typography>
                      )}
                    </div>
                    {li?.isChecked && withSecondLevel && (
                      <Grid
                        container
                        className={classes.checkboxContainer}
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: isSneakerCategory
                            ? 'center'
                            : 'space-between',
                        }}
                        key={`grid-${li?.id}`}
                      >
                        {isSneakerCategory
                          ? (() => {
                              return (
                                <div
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                  }}
                                >
                                  {li?.childInputs && (
                                    <Typography
                                      variant="body1"
                                      className={classes.warning}
                                      style={{ color: '#CC0011' }}
                                      data-testid={`warning-${index}`}
                                    >
                                      {li.childInputs[0].description ||
                                        t(
                                          'PSNAD.SubmissionForm.SelectASubReason'
                                        )}
                                    </Typography>
                                  )}
                                  <Dropdown
                                    id={`dropdown-${li.label}-${index}`.trim()}
                                    data-testid={`drop-${li.label}-${index}`.trim()}
                                    label={
                                      li.childInputs.find(
                                        (child) => child.isChecked
                                      )
                                        ? ''
                                        : t('select')
                                    }
                                    variant="secondary"
                                    value={
                                      li.childInputs.find(
                                        (child) => child.isChecked
                                      )?.label || ''
                                    }
                                    onChange={(e) =>
                                      handleSecondLevelSelect(
                                        li.label,
                                        e.target.value
                                      )
                                    }
                                  >
                                    {li.childInputs.map((child) => (
                                      <StyledMenuItem
                                        key={`option-${child.label.trim()}`}
                                        value={child.label}
                                        id={`option-${child.inputId}`}
                                      >
                                        {child.label}
                                      </StyledMenuItem>
                                    ))}
                                  </Dropdown>
                                </div>
                              );
                            })()
                          : (() => {
                              return li.childInputs.map((li2, childIndex) => {
                                return (
                                  <div
                                    key={`list-item-child-inputId-${
                                      li2.inputId || childIndex
                                    }`}
                                    style={{
                                      width: '50%',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      padding: '5px 0',
                                    }}
                                    data-testid="label-level-two"
                                  >
                                    <Toggle
                                      id={`toggle-switch-${li2.label}`}
                                      totalStates={3}
                                      value={
                                        li2.isChecked ? checked : 'neutral'
                                      }
                                      onClick={() =>
                                        handleSecondLevelSelect(
                                          li.label,
                                          li2.label
                                        )
                                      }
                                    />
                                    <Typography
                                      data-testid="accordion-item-label"
                                      variant="body1"
                                      id={`sec-lvl-label-${li2.label}`}
                                      style={{ flex: '1', marginLeft: '10px' }}
                                    >
                                      <text id={`sec-lvl-label-${li2.label}`}>
                                        {li2.label}
                                      </text>
                                    </Typography>
                                  </div>
                                );
                              });
                            })()}
                      </Grid>
                    )}
                  </div>
                </ListItem>
              );
            })}
        </List>
      </CustomAccordion>
    </>
  );
};

export default AccordionToggleList;

const Input = PropTypes.shape({
  inputId: PropTypes.string,
  parentId: PropTypes.string,
  hasPsnadValue: PropTypes.bool,
  snadCode: PropTypes.string,
  inputType: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  isPhotoRequired: PropTypes.bool,
  isChecked: PropTypes.bool,
});

Input.childInputs = PropTypes.arrayOf(Input);

AccordionToggleList.defaultProps = {
  title: '',
  checkType: 'success',
};

AccordionToggleList.propTypes = {
  title: PropTypes.string,
  listItems: PropTypes.arrayOf(Input).isRequired,
  onUpdateListItems: PropTypes.func.isRequired,
  checkType: PropTypes.string,
  setShouldBeDisabled: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
};
