import {
  PSNAD_STATUSES,
  PSNAD_RESOLVE_TYPE,
  CATEGORIES,
  CATEGORY,
  TC_CARD_PROTECTION,
} from '../../../../constants';

const initItemData = {
  itemId: '',
  orderId: '',
  lpn: '',
  evtn: '',
  title: '',
  condition: '',
  url: '',
  attributes: [],
  imageUrls: [],
};

const PsnadAutomationService = (
  exceptionData,
  stationData,
  imsLocationIdentifier
) => {
  const flagValue = exceptionData?.flagValue;
  const exceptionOrder = exceptionData?.order;
  const exception = exceptionOrder?.items?.find(
    (item) => item.lpn.lpn === flagValue
  );

  const category = exception?.category?.name;
  const isSneakerCategory = category === CATEGORY.sneaker;

  // PSNAD METADATA
  const psnad = () => [
    {
      name: 'PSNAD.PsnadDetails.ExceptionReason',
      value: exceptionData?.exceptionCode?.displayName,
    },
    {
      name: 'PSNAD.PsnadDetails.ExceptionNote',
      value: exceptionData?.notes[0]?.description,
    },
    {
      name: 'PSNAD.PsnadDetails.PsnadStatus',
      value: exceptionData?.psnadException.status,
    },
    {
      name: 'PSNAD.PsnadDetails.DaysSinceReported',
      value: exceptionData?.psnadException.daysSinceReported.toString(),
    },
    {
      name: 'PSNAD.PsnadDetails.ReportedTime',
      value: exceptionData?.psnadException.createdAt,
    },
    {
      name: 'PSNAD.PsnadDetails.ReportedBy',
      value: exceptionData?.createdBy?.userIdentification,
    },
    {
      name: 'PSNAD.PsnadDetails.UserRespondedTime',
      value: exceptionData?.psnadException.respondedAt,
    },
    {
      name: 'PSNAD.PsnadDetails.RespondedBy',
      value: exceptionData?.psnadException.respondedBy,
    },
    {
      name: 'PSNAD.PsnadDetails.SubmittedTime',
      value: exceptionData?.psnadException.submittedAt,
    },
    {
      name: 'PSNAD.PsnadDetails.SubmittedBy',
      value: exceptionData?.psnadException.submittedBy,
    },
    {
      name: imsLocationIdentifier
        ? 'PSNAD.PsnadDetails.StorageLocation'
        : 'PSNAD.PsnadDetails.Location',
      value:
        imsLocationIdentifier || exceptionData?.actualLocations[0]?.identifier,
    },
  ];

  const item = () => {
    if (exception) {
      const { evtn } = exceptionOrder;

      const { lpn } = exception.lpn;
      const itemId = exception.id;
      const { orderId } = exception;
      const {
        itemAttributes: attributes,
        itemUrl: url,
        title,
        itemCondition: condition,
      } = exception.ebayItem;
      const imageUrls = exception.allItemImages
        .filter((img) => !img.isPsnad)
        .map((img) => img.imageUrl);

      return {
        itemId,
        lpn,
        evtn,
        title,
        condition,
        url,
        attributes,
        imageUrls,
        orderId,
      };
    }
    return initItemData;
  };

  // PSNAD FORM FUNCTIONALITY
  const getSelectedInputIds = (inputs) => {
    return inputs.reduce((output, input) => {
      if (
        (input.isChecked && input.inputType === 'psnad') ||
        (input.isChecked && input.hasPsnadValue)
      ) {
        output.push(input.inputId);
      } else if (
        Array.isArray(input.childInputs) &&
        input.childInputs.length > 0
      ) {
        const { childInputs } = input;

        const selectedChildIds = getSelectedInputIds(childInputs);

        if (selectedChildIds.length > 0) {
          childInputs.forEach((childInput) => {
            const grandChildInputs = childInput.childInputs || [];
            const selectedGrandChildIds = getSelectedInputIds(grandChildInputs);

            output.push(...selectedGrandChildIds);

            if (selectedGrandChildIds.length === 0 && childInput.isChecked) {
              output.push(childInput.inputId);
            }
          });
        } else if (input.isChecked) {
          output.push(input.inputId);
        }
      }
      return output;
    }, []);
  };

  const selectOptionsByInputIds = (allPsnad, inputIds) => {
    return allPsnad.map((_psnad) => {
      const isChecked = inputIds.includes(_psnad.inputId);

      const updatedChildInputs = _psnad.childInputs
        ? selectOptionsByInputIds(_psnad.childInputs, inputIds)
        : [];

      const hasSelectedChild = updatedChildInputs.some(
        (child) => child.isChecked
      );

      return {
        ..._psnad,
        isChecked: isChecked || hasSelectedChild,
        childInputs: updatedChildInputs,
      };
    });
  };

  const selectOptionsBySnadCodes = (allInputs, snadCodes) => {
    if (isSneakerCategory) {
      return allInputs;
    }
    return allInputs.map((snad) => ({
      ...snad,
      childInputs: snad.childInputs.map((childInput) => {
        if (!childInput.snadCode) return { ...childInput };
        return {
          ...childInput,
          isChecked: snadCodes.includes(childInput.snadCode),
        };
      }),
    }));
  };

  const selectOptionsBySnadTitle = (allInputs, titles) => {
    if (isSneakerCategory) {
      return allInputs?.map((snad) => ({
        ...snad,
        childInputs: snad.childInputs.map((childInput) => {
          if (childInput.childInputs.length <= 1) {
            return {
              ...childInput,
              isChecked: titles.includes(childInput.title),
            };
          }

          return {
            ...childInput,
            childInputs: childInput.childInputs.map((grandChildInput) => {
              return {
                ...grandChildInput,
                isChecked: titles.includes(grandChildInput.title),
              };
            }),
            isChecked: titles.includes(childInput.title),
          };
        }),
      }));
    }

    // For non Sneaker Categories
    return allInputs.map((snad) => ({
      ...snad,
      childInputs: snad.childInputs.map((childInput) => {
        if (childInput.snadCode) return { ...childInput };
        return {
          ...childInput,
          isChecked: titles.includes(childInput.title),
        };
      }),
    }));
  };

  const transformToFormList = (output, snad) => {
    if (!snad || !output) {
      return [];
    }

    const findPsnadChildren = (input) => {
      if (!input) return false;

      if (input.hasPsnadValue) return true;

      return input.childInputs?.some(findPsnadChildren) || false;
    };

    const hasPsnadChildren = findPsnadChildren(snad);

    // If the exception is a TRADING CARD, we need to extract Raw or Graded Child Inputs depending on the Card Protection
    const isTradingCard = exception?.category?.name === CATEGORIES.tradingCard;
    const cardProtection = exception?.qcStationItem?.stationItemAnswers?.some(
      (itemValue) => itemValue?.text === TC_CARD_PROTECTION.graded
    )
      ? 'Graded'
      : 'Raw';

    if (isTradingCard) {
      const getCardProtectionTypeInputs = (inputs) => {
        const matchingInput = inputs?.find((input) => {
          return input?.title === cardProtection;
        });

        // Return the childInputs of the matching card protection type and hasPsnadValue
        return (
          matchingInput?.childInputs?.filter(
            (input) => input?.hasPsnadValue === true
          ) || []
        );
      };

      const cardProtectionTypeInputs = getCardProtectionTypeInputs(
        snad?.childInputs
      );

      // Returns the transformed exception object with flattened childInputs Raw or Graded to the output
      output.push({
        inputId: snad?.childInputs[0]?.id,
        parentId: snad.parentId,
        hasPsnadValue: snad.hasPsnadValue,
        snadCode: snad.snadCode,
        inputType: snad.inputType,
        title: snad.title,
        label: snad.title,
        isPhotoRequired: snad?.childInputs[0]?.isPhotoRequired || false,
        childInputs: cardProtectionTypeInputs.reduce(transformToFormList, []),
        isChecked: false,
      });

      return output;
    }

    const filterTopLevel = !snad.parentId && hasPsnadChildren;
    const filterSecondLevel = snad.hasPsnadValue;
    const filterThirdLevel = snad?.childInputs?.some((childInput) => {
      // Check if any grandchild input has hasPsnad set to true
      return childInput?.childInputs?.some(
        (grandChildInput) => grandChildInput.hasPsnadValue
      );
    });

    // Refers to SNAD with 1 PSNAD matching in title and/or snad-code
    const isOneToOneSnadPsnad =
      Array.isArray(snad.childInputs) &&
      snad.childInputs.length === 1 &&
      snad.childInputs[0].inputType === 'psnad' &&
      snad.inputType === 'snad' &&
      (snad.childInputs[0].title === snad.title ||
        snad.childInputs[0].snadCode === snad.snadCode);

    const isPsnadPhotoRequired = snad?.childInputs?.[0]?.isPhotoRequired;

    const isPhotoRequiredSneaker =
      snad?.childInputs?.length === 1
        ? snad.childInputs[0]?.isPhotoRequired
        : snad?.isPhotoRequired;

    if (filterSecondLevel || filterTopLevel || filterThirdLevel) {
      // When relationship is 1-to-1 between SNAD & PSNAD, we use the PSNAD as parent without childInputs
      // when relationship is 1-to-Many between SNAD & PSNADs, we use the SNAD as the parent, and PSNAD as the childInputs
      const psnadInput = isOneToOneSnadPsnad ? snad.childInputs[0] : snad;

      return output.concat({
        inputId: psnadInput.id,
        parentId: psnadInput.parentId,
        hasPsnadValue: psnadInput.hasPsnadValue,
        snadCode: psnadInput.snadCode,
        inputType: psnadInput.inputType,
        title: psnadInput.title,
        label: psnadInput.title,
        isPhotoRequired: isSneakerCategory
          ? isPhotoRequiredSneaker
          : isPsnadPhotoRequired,
        description: psnadInput.description,
        childInputs: Array.isArray(psnadInput.childInputs)
          ? psnadInput.childInputs.reduce(transformToFormList, [])
          : [],
        isChecked: false,
      });
    }

    return output;
  };

  const parseQueryStationInputs = (inputs) => {
    // Logic to parse queryStation response into snad-psnad submission form
    return inputs
      .reduce(transformToFormList, [])
      .filter(
        (topLevel) =>
          ['snad', 'psnad'].includes(topLevel.inputType) && topLevel.childInputs
      );
  };

  const getPsnadList = () => {
    const initialList = parseQueryStationInputs(stationData.inputs);

    const psnadStationItemAnswer =
      exception.psnadStationItem?.stationItemAnswers;
    const qcStationItemAnswer = exception.qcStationItem?.stationItemAnswers;

    if (psnadStationItemAnswer) {
      // autofill list with psnadStationItemAnswer
      const failedPsnadInputIds = psnadStationItemAnswer.map(
        (sia) => sia.input.id
      );
      return selectOptionsByInputIds(initialList, failedPsnadInputIds);
    }

    if (qcStationItemAnswer) {
      // autofill list with qcStationItemAnswer
      // For Customized SNADs without a snadCode
      const titles = qcStationItemAnswer
        .filter((sia) => {
          if (isSneakerCategory) {
            return sia.grade === 'FAIL';
          }
          return sia.grade === 'fail' && !sia.input.snadCode;
        })
        .map((sia) => sia.input.title);

      // For all other SNADs with a snadCode
      const snadCodes = qcStationItemAnswer
        .filter((sia) => sia.grade === 'fail')
        .map((sia) => sia.input.snadCode)
        .filter((snadCode) => snadCode); // remove top_level inputs with no snad_codes

      const checkedTitleList = selectOptionsBySnadTitle(initialList, titles);
      return selectOptionsBySnadCodes(checkedTitleList, snadCodes);
    }

    return initialList;
  };

  const getSelectedPsnadList = () => {
    const psnadForm = getPsnadList();

    const subList = (inputs) =>
      inputs
        .filter((input) => input.isChecked)
        .map((input) => ({
          id: input.inputId,
          name: input.title,
          subList: subList(input.childInputs),
        }));

    return psnadForm
      .map((topLevel) => ({
        id: topLevel.inputId,
        name: topLevel.title,
        subList: subList(topLevel.childInputs),
      }))
      .filter((topLevel) => topLevel.subList.length > 0);
  };

  // PSNAD RESOLVER FUNCTIONALITY
  const getPredefinedNote = (psnadStatus) => {
    const predefinedNotes = {
      cancelled: ['Cancelled order'],
      accepted: ['Item accepted, please pass authentication'],
      rejected: ['Item rejected, please SNAD'],
      none: ['No action required'],
    };

    return predefinedNotes[psnadStatus];
  };

  const getResolverBtnText = (psnadStatus) => {
    const btnText = {
      cancelled: 'Submit',
      accepted: 'Submit',
      rejected: 'Submit',
      none: 'SendToQc',
    };

    return btnText[psnadStatus];
  };

  const getResolutionAction = (psnadStatus) => {
    const resolutionActions = {
      cancelled: {
        key: PSNAD_RESOLVE_TYPE.cancelled,
        value: 'SendToPackAndShip',
      },
      accepted: { key: PSNAD_RESOLVE_TYPE.accepted, value: 'SendToQc' },
      rejected: { key: PSNAD_RESOLVE_TYPE.rejected, value: 'SendToQc' },
      none: { key: PSNAD_RESOLVE_TYPE.noActionRequired, value: 'SendToQc' },
    };

    return resolutionActions[psnadStatus];
  };

  const resolver = (resolveType) => {
    // NOT_ELIGIBLE will be equivalent to CANCELLED
    const key =
      resolveType === PSNAD_STATUSES.notEligible
        ? PSNAD_STATUSES.cancelled.toLowerCase()
        : resolveType.toLowerCase();

    const titleText = {
      cancelled: 'CancelledOrder',
      accepted: 'ResolvePsnad',
      rejected: 'ResolvePsnad',
      none: 'SendToQc',
    };

    return {
      title: titleText[key],
      resolverBtnTxt: getResolverBtnText(key),
      resolutionOption: getResolutionAction(key),
      predefinedNotes: getPredefinedNote(key),
    };
  };

  return {
    form: {
      getSelectedInputIds,
      getPsnadList,
      getSelectedPsnadList,
    },
    metadata: { psnad, item },
    itemException: exception,
    resolver,
  };
};

export { initItemData, PsnadAutomationService };
